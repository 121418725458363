html, body {
    margin: 0;
    height: 100%;
    width: 100%;
}

h2#loading {
    width: 100%;
    text-align: center;
    margin-top: 15%;
}

canvas#draw {
    height: 100%;
    max-width: 100%;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    padding: 0;
    margin: 0 auto;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    max-height: 46vw;
}

div#root {
    height: 100%;
    width: 100%;
}

@keyframes pulse {
    0%, 100% {
        background-color: #001F3F;
    }
    50% {
        background-color: #408484;
        ;
    }
}

div#main {
    background-color: #728b9c;
    animation: pulse 30s infinite;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 4rem;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
    width: 100%;
}

div#main:fullscreen {
    width: 100vw;
    height: 100vh;
    background-color: #728b9c;
}

div#drawspace {
    padding: 1rem;
    display: flex;
    align-content: center;
}

div#buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

button#publish {
    border: none;
    background-color: black;
    padding: 0.5rem;
    color: #c8c8c8;
}

span.controls {
    padding-left: 1rem;
    display: inline-flex;
    align-items: center;
}

.button {
    cursor: pointer;
    border: none;
    background-color: black;
    padding: 0.5rem;
    margin-right: 1rem;
    color: #c8c8c8;
}

.button:disabled {
    cursor: default;
    opacity: 0.2;
}

a {
    padding-left: 1rem;
}

.loader {
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.roundel-button {
    transition: all 200ms ease-in-out;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: 3px;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}